import { useEffect } from 'react';
import type { NextPage } from 'next';
import Link from 'next/link';
import Head from 'next/head';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gtm } from '@/lib/gtm/gtm';
import { useRouter } from 'next/router';
const NotFound: NextPage = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    gtm.push({
      event: 'page_view'
    });
  }, []);
  const router = useRouter();
  const handleGoBack = () => {
    router.back();
  };
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="404.tsx">
        <title>Oops! Page Not Found | RipeMetrics</title>
      </Head>
      <div className="flex flex-col min-h-screen bg-gradient-to-b from-white to-orange-50">
        <main className="flex flex-col justify-center grow w-full px-6 mx-auto max-w-7xl lg:px-8">
          <div className="flex justify-center mb-8">
            <Link href="/" className="inline-flex" data-sentry-element="Link" data-sentry-source-file="404.tsx">
              <span className="sr-only">RipeMetrics</span>
              <img className="w-auto h-12" src="/apple-touch-icon.png" alt="" />
            </Link>
          </div>

          <div className="flex flex-col items-center justify-center py-16 text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-8 bg-orange-100 rounded-full">
              <span className="text-2xl">🔍</span>
            </div>

            <p className="text-lg font-semibold text-orange-600">404</p>

            <h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Plot twist! This page is playing hide and seek
            </h1>

            <p className="mt-6 text-lg leading-8 text-gray-600">
              Even our advanced algorithms couldn&apos;t track down this page.
              But don&apos;t worry – we&apos;ve got plenty of other data-driven
              insights waiting for you!
            </p>

            <div className="flex flex-col items-center gap-4 mt-10 sm:flex-row sm:gap-6">
              <button onClick={handleGoBack} className="inline-flex items-center px-4 py-2 text-sm font-semibold text-white transition-colors duration-200 bg-orange-600 rounded-md shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                <span className="mr-2">←</span> Go back
              </button>

              <Link href="https://ripemetrics.com/contact" target="_blank" rel="noopener noreferrer" className="inline-flex items-center text-sm font-semibold text-gray-900 transition-colors duration-200 hover:text-orange-600" data-sentry-element="Link" data-sentry-source-file="404.tsx">
                Report this missing page <span className="ml-2">→</span>
              </Link>
            </div>

            <p className="mt-8 text-sm text-gray-500">
              Quick tip: Check the URL for typos, or explore our navigation menu
              to find what you need.
            </p>
          </div>
        </main>
      </div>
    </>;
};
export default NotFound;